import * as React from 'react'

import Layout from '../../components/layout'

export default function GeneTree(): JSX.Element {
  return (
    <Layout>
      <div id="GeneTreeWindow" />
    </Layout>
  )
}
